<template>
    <div class="body-container">
        <BannerSection title="Empeño fácil: funcionamiento, <br class='d-none d-lg-block'/>ventajas y proceso" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <h1 class="bold-text"
                        style="font-size: 25px;">Empeño fácil: ¿cómo aprovechar esta opción?</h1>
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/mujerPensando.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>
                    <p class="mb-0">
                        ¿Estás pasando por un aprieto económico? Si tus finanzas no van tan bien, puedes optar por el
                        empeño fácil, una solución rápida y accesible que alivia tu bolsillo en tiempos de necesidad.
                    </p>
                    <br>
                    <p>A diferencia de otros métodos de financiamiento que pueden ser lentos y burocráticos, esta opción
                        ofrece una forma directa y sin complicaciones de obtener el dinero que necesitas.
                    </p>
                    <p></p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo funciona el empeño fácil?
                        </h2>
                        
                    </span>

                    <p>
                        Consiste en obtener un préstamo de manera sencilla, poniendo como garantía un bien de valor, que
                        puede ser por ejemplo tu vehículo. Lo atractivo de este modelo es que consigues el dinero que
                        necesitas en poco tiempo y en unos pocos pasos.
                    </p>
                    <p>Una alternativa es aprovechar el empeño fácil con empresas como Ibancar. Con nosotros no
                        necesitas entregar físicamente tu coche, simplemente lo utilizas como garantía. Lo mejor de todo
                        es que puedes seguir usándolo mientras pagas el préstamo.
                    </p>

                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Ventajas de este tipo de empeño</h2>
                    </span>
                    <p>Las ventajas de optar por un empeño fácil incluyen: </p>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text">
                                Acceso rápido a fondos:
                            </p>
                            <span>puedes obtener el dinero en apenas 48 horas después de solicitarlo. </span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Sin necesidad de cambio de titularidad:
                            </p>
                            <span>sigues siendo el propietario de tu vehículo durante el periodo del préstamo.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Proceso online:
                            </p>
                            <span>olvídate de los trámites tardíos. Con el empeño fácil puedes hacer todo el proceso en
                                línea, sin salir de casa.</span>
                        </li>

                    </ul>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Requisitos</h2>
                        
                    </span>
                    <p>Para acceder a un empeño fácil, necesitas:</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">Ser el propietario y titular del vehículo que utilizarás como aval.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">La factura original del coche.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tener todos los documentos en regla.
                                </span>

                            </p>
                        </li>
                        <br>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <h2 class="bold-text"
                        style="font-size: 20px;">¿Qué documentación necesitas para un empeño fácil?</h2>
                    
                    <p>En cuanto a la documentación requerida para gestionar este trámite, necesitarás: </p>
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/TableroCarro.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">INE o pasaporte
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Foto tipo selfie con tu INE
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Comprobante de ingresos y de domicilio (con vigencia de 3 meses máximo)
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tarjeta de circulación.
                                </span>

                            </p>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0">
                                <span class="">Fotografías del vehículo
                                </span>

                            </p>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0">
                                <span class="">Fotografías del coche.
                                </span>

                            </p>
                        </li>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Proceso 100% online para un empeño fácil
                        </h2>
                        
                    </span>

                    <p>
                        Realizar la solicitud para obtener la alternativa que necesitas empeñar tu coche es muy
                        sencillo. Solo debes:
                    </p>
                    <br>
                    <ol>
                        <li>
                            <span>Enviar tu solicitud en línea mediante el formulario web. Donde recibirás un préstamo
                                preaprobado al instante. </span>
                        </li>

                        <br>

                        <li>
                            <span>Aportar la documentación necesaria para tramitar tu solicitud. Una vez verificada,
                                nuestro equipo agendará una visita para revisar el vehículo. </span>
                        </li>
                        <br>

                        <li>
                            <span>Una vez recogida la factura y firmado el contrato, recibirás el dinero lo antes
                                posible en la cuenta que desees. </span>
                        </li>
                        <br>
                    </ol>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Preguntas frecuentes</h2>
                        
                    </span>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Cuánto dinero puedo obtener a través de un
                            empeño fácil?</h3>
                        <p style="font-size: 17px;">La cantidad de dinero a recibir depende del valor de mercado de tu
                            vehículo. En Ibancar, ofrecemos préstamos de hasta el 70% del valor actual de tu coche. </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Qué sucede si no puedo pagar el préstamo en
                            el tiempo acordado?</h3>
                        <p style="font-size: 17px;">Si enfrentas dificultades para cumplir con los pagos del préstamo,
                            es crucial que te comuniques con nosotros lo antes posible. En Ibancar, ofrecemos
                            flexibilidad en los planes de pago y podemos ajustarlos según tus circunstancias. </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">-  ¿Es necesario tener un historial crediticio limpio para acceder al empeño fácil?</h3>
                        <p style="font-size: 17px;">No es imprescindible. Puedes aparecer en el <b>Buró de Crédito</b>. La evaluación se hará en función de tu auto y condiciones actuales. </p>
                    </div>
                    <br>

                    <h2 style="font-size: 20px;"
                        class="bold-text">Contacta con nosotros para un empeño fácil</h2>
                    
                    <p>Si estás interesado en obtener más información o deseas iniciar el proceso de empeño fácil, contáctanos.  </p>
                    <p>Te ofrecemos un servicio rápido y amigable. Además, podrás seguir usando tu coche mientras pagas el préstamo, así no te quedarás sin medio de transporte. </p>

                    <p>¡En <a href="https://ibancar.mx/contacto">Ibancar</a> estamos listos para ayudarte a resolver tus necesidades financieras sin complicaciones!</p>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'EmpenoFacil',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Empeño fácil | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Con nosotros, el empeño de tu auto es fácil y rápido. Recibe dinero sin complicaciones, con requisitos mínimos y atención inmediata. ¡Solicita tu préstamo hoy!'
                    },
                    {
                        name: 'keywords',
                        content: 'empeño facil'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/empeno-facil', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>